import type { Category } from '../types'

export const useCasinoStore = defineStore('casino', () => {
  const { locale } = useI18n()

  const { data: categories, pending } = useStFetch('/casino/category/find', {
    method: 'post',
    body: computed(() => ({
      params: {
        language: locale.value as any,
      },
      pagination: {
        page: 0,
        perPage: 50,
        orderBy: [
          {
            fieldName: 'weight' as const,
            sortOrder: 'DESC' as const,
          },
        ],
      },
    })),
    transform: (response) =>
      response.data.map((category) => ({
        ...category,
        name: category.name || category.code,
      })),
    default: () => [],
  })

  const mainCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.isMain),
  )

  const mainPageCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.type === 'mainPage'),
  )

  const tabCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.visibleForScrollbar),
  )

  const menuCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.visibleForLeftMenu),
  )

  const categoryMap = computed(() =>
    categories.value.reduce<Map<string, Category>>((acc, category) => {
      acc.set(category.code, category)
      return acc
    }, new Map()),
  )

  function getCategoryByCode(code: string): Category | undefined {
    return categoryMap.value.get(code)
  }

  function getCategoryNameById(id: number): Category['name'] | undefined {
    return categories.value.find((category) => category.id === id)?.name
  }
  const isLoading = computed(() => pending.value && !categories.value.length)

  return {
    categories,
    mainCategories,
    mainPageCategories,
    tabCategories,
    menuCategories,
    isLoading,
    getCategoryByCode,
    getCategoryNameById,
  }
})
